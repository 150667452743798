var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"contact-profile","fluid":"","tag":"section"}},[_c('v-overlay',{attrs:{"value":_vm.isOverlayVisible}},[_c('v-progress-circular',{staticClass:"spinner",attrs:{"size":70,"width":7,"color":"amber","indeterminate":""}})],1),(Object.keys(_vm.gmbDetails).length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"messageInfoWrapper"},[_c('div',{staticClass:"messageMetaData"},[_c('h3',[_vm._v("Account Name: "+_vm._s(_vm.gmbDetails.account[0].accountName))]),_c('h3',[_vm._v("Location Name: "+_vm._s(_vm.gmbDetails.locations[0].locationName))]),_c('p',{staticClass:"statsData"},[_vm._v(" Total Reviews: "+_vm._s(_vm.gmbDetails.reviews.totalReviewCount)+" ")]),_c('p',{staticClass:"ratingLabel"},[_vm._v(" Average Rating: "),_c('star-rating',{attrs:{"text-class":"hide-text","star-size":"18","increment":0.01,"rating":_vm.gmbDetails.reviews.averageRating,"read-only":""}})],1),_c('p',[_vm._v("Website: "),_c('a',{attrs:{"href":_vm.gmbDetails.locations[0].websiteUrl}},[_vm._v(_vm._s(_vm.gmbDetails.locations[0].websiteUrl))])])])])])],1):_vm._e(),(Object.keys(_vm.gmbDetails).length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.gmbDetails.reviews.reviews,"item-key":"reviewId","sort-by":"Date"},scopedSlots:_vm._u([{key:"item.reviewer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"profile_item"},[_c('img',{staticClass:"profile_pic",attrs:{"src":item.reviewer.profilePhotoUrl}}),_c('p',[_vm._v(_vm._s(item.reviewer.displayName))])])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" "),(item.hasOwnProperty('reviewReply') && item.reviewReply !== null)?_c('div',{staticClass:"reply-review"},[_c('div',{staticClass:"reply-text-content"},[_c('p',[_vm._v(_vm._s(item.reviewReply.comment))]),_c('p',[_vm._v(_vm._s(_vm.getHumanDate(item.reviewReply.updateTime)))])]),_c('div',{staticClass:"senderActions"},[_c('v-icon',{attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Delete"},on:{"click":function($event){return _vm.deleteReply(item)}}},[_vm._v(" mdi-delete ")])],1)]):_vm._e()]}},{key:"item.starRating",fn:function(ref){
var item = ref.item;
return [_c('star-rating',{attrs:{"text-class":"hide-text","star-size":"18","increment":0.01,"rating":_vm.wordToNumber(item.starRating),"read-only":""}})]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanDate(item.createTime))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-4",attrs:{"color":"primary","dark":"","disabled":(item.comment && item.comment.length)},on:{"click":function($event){return _vm.selectReview(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"data-toggle":"tooltip","data-placement":"top","title":"Edit"}},[_vm._v(" mdi-pencil-box ")])],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Review for reply")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"required":"","label":"Add your reply*","error":!(
                            _vm.reviewReply && _vm.reviewReply.length
                          ) && _vm.isFormDirty},model:{value:(_vm.reviewReply),callback:function ($$v) {_vm.reviewReply=$$v},expression:"reviewReply"}})],1)],1)],1),_c('small',[_vm._v("* indicates required field")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.replyToReview(item)}}},[_vm._v(" Save ")])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('p',{staticClass:"mb-4 mt-4"},[_vm._v(" No reviews to display ")])]},proxy:true}],null,false,377678191)})],1)],1):_vm._e(),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.notification)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }