<template>
  <v-container
    id="contact-profile"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>

    <v-row v-if="Object.keys(gmbDetails).length > 0">
      <v-col
        cols="12"
      >
        <div class="messageInfoWrapper">
          <div class="messageMetaData">
            <h3>Account Name: {{ gmbDetails.account[0].accountName }}</h3>
            <h3>Location Name: {{ gmbDetails.locations[0].locationName }}</h3>
            <p class="statsData">
              Total Reviews: {{ gmbDetails.reviews.totalReviewCount }}
            </p>
            <p class="ratingLabel">
              Average Rating:    &nbsp;       <star-rating
                text-class="hide-text"
                star-size="18"
                :increment="0.01"
                :rating="gmbDetails.reviews.averageRating"
                read-only
              />
            </p>
            <p>Website: <a :href="gmbDetails.locations[0].websiteUrl">{{ gmbDetails.locations[0].websiteUrl }}</a></p>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="Object.keys(gmbDetails).length > 0">
      <v-col
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="gmbDetails.reviews.reviews"
          item-key="reviewId"
          sort-by="Date"
        >
          <template
            v-slot:item.reviewer="{ item }"
          >
            <div class="profile_item">
              <img
                class="profile_pic"
                :src="item.reviewer.profilePhotoUrl"
              >
              <p>{{ item.reviewer.displayName }}</p>
            </div>
          </template>
          <template
            v-slot:item.comment="{ item }"
          >
            {{ item.comment }}

            <div
              v-if="item.hasOwnProperty('reviewReply') && item.reviewReply !== null"
              class="reply-review"
            >
              <div class="reply-text-content">
                <p>{{ item.reviewReply.comment }}</p>
                <p>{{ getHumanDate(item.reviewReply.updateTime) }}</p>
              </div>

              <div class="senderActions">
                <v-icon
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete"
                  @click="deleteReply(item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </div>
          </template>
          <template
            v-slot:item.starRating="{ item }"
          >
            <star-rating
              text-class="hide-text"
              star-size="18"
              :increment="0.01"
              :rating="wordToNumber(item.starRating)"
              read-only
            />
          </template>
          <template
            v-slot:item.createTime="{ item }"
          >
            {{ getHumanDate(item.createTime) }}
          </template>
          <template
            v-slot:item.actions="{ item }"
          >
            <v-dialog
              v-model="dialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2 ml-4"
                  v-bind="attrs"
                  :disabled="(item.comment && item.comment.length)"
                  v-on="on"
                  @click="selectReview(item)"
                >
                  <v-icon
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit"
                  >
                    mdi-pencil-box
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Add Review for reply</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <v-text-field
                          v-model="reviewReply"
                          required
                          label="Add your reply*"
                          :error="
                            !(
                              reviewReply && reviewReply.length
                            ) && isFormDirty
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>* indicates required field</small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="secondary"
                    text
                    @click="closeDialog"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="replyToReview(item)"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:no-data>
            <p class="mb-4 mt-4">
              No reviews to display
            </p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import GMB from '../../../utils/google-review'
  import moment from 'moment'
  import wordsToNumbers from 'words-to-numbers'
  import StarRating from 'vue-star-rating'
  const { gapi } = window

  export default {
    components: {
      StarRating,
    },
    props: {},
    data: () => ({
      gmbConnector: new GMB(),
      gmbEndpoint: process.env.VUE_APP_GMB_BASE_URL,
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      googleTranslateApiKey: process.env.VUE_APP_GOOGLE_TRANSLATE_API,
      googleTranslateClientId: process.env.VUE_APP_GOOGLE_TRANSLATE_API_CLIENT_ID,
      gmbDetails: {},
      isFormDirty: false,
      reviewReply: '',
      notification: '',
      snackbar: false,
      dialog: false,
      selectedReveiw: null,
      headers: [
        { text: 'Name', value: 'reviewer' },
        { text: 'Comment', value: 'comment', width: '50%' },
        { text: 'Rating', value: 'starRating' },
        { text: 'Date', value: 'createTime' },
        { text: 'Action', value: 'actions' },
      ],
      isOverlayVisible: false,
    }),

    watch: {
      dialog (val) {
        val || this.closeDialog()
      },
    },

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    mounted () {},

    methods: {
      wordToNumber (word) {
        return wordsToNumbers(word)
      },
      async initialize () {
        this.isOverlayVisible = true
        gapi.load('client:auth2', this.initClient)
      },

      getHumanDate (date) {
        return moment(date).format('DD/MM/YYYY,h:mm:ss')
      },

      initClient () {
        const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/translate/v2/rest']

        const SCOPES = 'https://www.googleapis.com/auth/business.manage' // ['https://www.googleapis.com/auth/cloud-platform', 'https://www.googleapis.com/auth/cloud-translation', 'https://www.googleapis.com/auth/plus.business.manage', 'https://www.googleapis.com/auth/business.manage']
        const { gapi } = window
        gapi.client.init({
          apiKey: this.googleTranslateApiKey,
          clientId: this.googleTranslateClientId,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        }).then(() => {
          gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus)
          // Handle the initial sign-in state.
          this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
        }, (e) => {})
      },

      async updateSigninStatus (isSignedIn) {
        if (isSignedIn) {
          this.getGMBDetails()
        } else {
          this.showGoogleSignInPopup()
        }
      },

      async showGoogleSignInPopup () {
        gapi.auth2.getAuthInstance().signIn().then(() => {
          gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus)
          this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
        }, (e) => {})
      },

      async getGMBDetails () {
        try {
          this.isOverlayVisible = true
          const accountsApi = await this.gmbConnector.gmbRequest('get', `${this.gmbEndpoint}accounts`, {}, gapi)
          const { accounts } = accountsApi.data
          this.gmbDetails.account = accounts
          const locationApi = await this.gmbConnector.gmbRequest('get', `${this.gmbEndpoint}${accounts[0].name}/locations`, {}, gapi)
          const { locations } = locationApi.data
          this.gmbDetails.locations = locations
          const reviewsApi = await this.gmbConnector.gmbRequest('get', `${this.gmbEndpoint}${locations[0].name}/reviews`, {}, gapi)
          const reviews = reviewsApi.data
          this.gmbDetails.reviews = reviews
          this.isOverlayVisible = false
        } catch (e) {
          if ('response' in e) {
            const { response } = e
            const { request, ...errorObject } = response

            if ('error' in errorObject.data && errorObject.data.error.code === 401) {
              this.showGoogleSignInPopup()
            }
          }
        }
      },

      isFormValid () {
        let isValid = true
        this.notification = ''
        if (!this.reviewReply || !this.reviewReply) {
          this.notification = 'Fill all the required fields'
          isValid = false
        }
        return isValid
      },

      selectReview (item) {
        this.selectedReveiw = item
      },

      async replyToReview () {
        try {
          this.isFormDirty = true
          if (!this.isFormValid()) {
            this.snackbar = true
            return
          }

          this.closeDialog()
          this.isOverlayVisible = true

          const replyReview = await this.gmbConnector.gmbRequest('put', `${this.gmbEndpoint}${this.gmbDetails.locations[0].name}/reviews/${this.selectedReveiw.reviewId}/reply`, {
            comment: this.reviewReply,
          }, gapi)

          if (replyReview) {
            this.getGMBDetails()
          }
        } catch (e) {
          if ('response' in e) {
            const { response } = e
            const { request, ...errorObject } = response

            if ('error' in errorObject.data && errorObject.data.error.code === 401) {
              this.showGoogleSignInPopup()
            }
          }
        }
      },

      closeDialog () {
        this.dialog = false
        this.$nextTick(() => {
          this.reviewReply = ''
          this.selectedReveiw = null
          this.isFormDirty = false
        })
      },

      async deleteReply (item) {
        try {
          this.isOverlayVisible = true

          const deleteReply = await this.gmbConnector.gmbRequest('delete', `${this.gmbEndpoint}${this.gmbDetails.locations[0].name}/reviews/${item.reviewId}/reply`, {}, gapi)
          if (deleteReply) {
            this.getGMBDetails()
          }
        } catch (e) {
          if ('response' in e) {
            const { response } = e
            const { request, ...errorObject } = response

            if ('error' in errorObject.data && errorObject.data.error.code === 401) {
              this.showGoogleSignInPopup()
            }
          }
        }
      },
    },
  }

</script>

<style scoped lang="scss">
  .messageInfoWrapper {
    width: 100%;
    background-color: white;
    min-height: 100px;
    max-height: 280px;
    display: flex;
    @media only screen and (max-width: 968px) {
        display: block;
    }
    .messageMetaData {
      width: 50%;
      padding: 40px 20px 30px 60px;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      h3 {
        margin-bottom: 6px;
      }
      .dateDisplay {
        margin-bottom: 75px;
      }
      p {
        margin-bottom: 3px;
      }
    }
    .graphWrapper {
      width: 50%;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .pieChartWrapper {
        width: 280px;
      }
    }
  }
  .mr-1 {
    margin-right: 5px;
  }
  .senderActions {
    display: flex;
  }
  .selectMessageInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 200px;
  }
    .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .statusFilterWrapper {
    margin-top: 25px;
    margin-bottom: -30px;
  }
  .orLabel {
    text-align: center;
    // align-items: center;
    padding-top: 25px;
  }
  .reset {
    color: red;
    cursor: pointer;
    text-align: center;
  }
  .statsData {
    margin-top: 5rem;
  }
  .profile_item {
    display: flex;
    align-items: end;
  }
  .profile_pic {
    object-fit: contain;
    height: 3rem;
    margin-right: 1rem;
  }
  .reply-review {
    display: flex;
    padding: 1rem 0rem;
    align-items: center;
    justify-content: space-between;
    margin-left: 2rem
  }
  .reply-text-content > p {
    margin: 0px
  }
  .ratingLabel {
    display: flex;
  }
</style>

<style>
  .hide-text {
    color: rgba(255, 255, 255, 0);
    font-size: 0px;
  }
</style>
