import axios from 'axios'

export default class GMB {
    async gmbRequest (methodType, url, payload, gapi) {
        const idToken = await gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token
        const headers = {
            Authorization: `Bearer ${idToken}`,
        }
        if (methodType.toLowerCase() === 'get') {
            return axios[methodType](url, { headers, crossdomain: true })
        } else if (methodType.toLowerCase() === 'delete') {
            return axios[methodType](url, { headers, crossdomain: true })
        } else {
            return axios[methodType](url, payload, { headers, crossdomain: true })
        }
    }
}
